// const LazyBgObserver = new IntersectionObserver(entries=> {
// 	entries.forEach(entry=> {

// 		if(entry.isIntersecting) {
// 			let animationClasses = entry.target.dataset.animate || '';

// 			animationClasses = animationClasses.split(' ');

// 			animationClasses.forEach(classe => entry.target.classList.add(classe));

// 			LazyBgObserver.unobserve(entry.target);
// 		}

// 	});

// }, {
// 	rootMargin: '200px 200px 200px 200px'
// });

// const lazyBg = document.querySelectorAll('[data-lazy-bg]');
// lazyBg.forEach(el => LazyBgObserver.observe(el));
